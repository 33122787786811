import React from 'react'
import './cta.css'

const CTA = () => {
  return (
    <div className="modern__cta">
      <div className="modern__cta-content">
        <p>Take a deep dive into our incredible developement team and this project if you wish...</p>
        <ul>
          <li>Full Stack MERN specializing in Front End React/ Full Stack Python paired with Flask, MySQL & Bootstrap. </li>
          <li>This project was crafted on a well-built foundation, including a dynamic folder hierarchy, borrowing of components with strategic imports and exports.</li>
          <li>Tools that assist us are: Ground-Up CSS engineering, Figma, Animista, Google Fonts, React-Icon imports and much more. </li>
        </ul>
      </div>
        <div className="modern__cta-btn">
          <button type="button">Learn More</button>
        </div>
    </div>
  )
}

export default CTA
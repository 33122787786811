import React from 'react'
import './blog.css' 
import { Article } from '../../components'
import { blog01, blog02, blog03, blog04, blog05 } from './imports';

const Blog = () => {
  return (
    <div className="modern__blog section__padding" id="blog">
      <div className="modern__blog-heading" id="blogg">
        <h1 className="gradient__text">A lot's happening, We're blogging about it.</h1>
      </div>
      <div className="modern__blog-container">
        <div className="modern__blog-container_groupA">
            <Article imgUrl={blog01}  date="Feb 8th, 2023"  title="Modern AI is the future. Allow us elaborate..."/>
        </div>
        <div className="modern__blog-container_groupB">
            <Article imgUrl={blog02} date="Feb 8th, 2023" title="Let's explore the possibilites"/>
            <Article imgUrl={blog03} date="Feb 8th, 2023" title="Industry Analytics and Full Stack Development"/>
            <Article imgUrl={blog04} date="Feb 8th, 2023" title="Senior Software Dev's/Product Managers"/>
            <Article imgUrl={blog05} date="Feb 8th, 2023" title="Modern AI in 20 years"/>
        </div>
      </div>
    </div>
  )
}

export default Blog
import React from 'react'
import './header.css'
import people from '../../assets/people.png'
import ai from '../../assets/ai.png'
import possibility from '../../assets/possibility.png'
import {  RiWechat2Fill } from 'react-icons/ri'

const Header = () => {
  return (
    <div className="modern__header section__padding" id="home">
      <div className="modern__header-content">
        <h1 className="gradient__text">We are individuals discovering the natural elegance this world has to offer, in a not so natural way, through AI.</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus exercitationem repudiandae debitis vero, tempora quas nulla suscipit ut aut quod odit perspiciatis voluptates necessitatibus. Atque, ut. Rem perspiciatis laboriosam cupiditate, tempora vero architecto dolorum facilis optio animi, nulla accusantium iste doloribus eum impedit fuga! </p>

        <div className="modern__header-content__input">
          <input type="email" placeholder="Email Address Here..."/>
          <button type="button">Inquire</button>
        </div>

        <div className="modern__header-content__people">
          <img src={people} alt="people"/>
          <p>1,600 individuals requesting access to Modern AI</p>
          <RiWechat2Fill class="RiWechat2Fill"size={25} opacity={.5} />
        </div>

      </div>
        <div className="modern__header-image">
          <img src={possibility} alt="ai" />
        </div>
      </div>
  )
}

export default Header
import React, { useState } from 'react'
import { RiMenu3Line, RiGlobalFill,} from 'react-icons/ri';
import './navbar.css'

//BEM -Block Element modifiers
const Menu = () => (
  <>
    <p><a href="#home">Who We Are</a></p>
    <p><a href="#modernai">What is Modern AI?</a></p>
    <p><a href="#possibility">World of Tech</a></p>
    <p><a href="#features">Collaboration</a></p>
    <p><a href="#blog">Blog</a></p>
  </>
)
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="modern__navbar">
      <div className="modern__navbar-links">
        <div className="modern__navbar-links_logo">
          <h3>Mordern AI</h3>
          {/* <img src={logo} alt="logo" /> */}
        </div>
        <div className="modern__navbar-links_container">
        <Menu />
        </div>
      </div>
      <div className="modern__navbar-sign">
        <p>Sign in</p>
        <button type="button">Sign up</button>
      </div>
      <div className="modern__navbar-menu">
      {toggleMenu
        ? <RiGlobalFill color="fff" size={27} onClick={() => setToggleMenu(false)} />
        : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
    }
    {toggleMenu && (
      <div className="modern__navbar-menu_container scale-up-center">
        <div className="modern__navbar-menu_container-links">
        <Menu />
        <div className="modern__navbar-menu_container-links-sign">
        <p>Sign in</p>
        <button type="button">Sign up</button>
      </div>
        </div>
      </div>
    )} 
      </div>
    </div>
  )
}

export default Navbar
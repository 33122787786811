import React from 'react'
import './brand.css'
import { google, dropbox, shopify } from './imports'

const Brand = () => {
  return (
    <div className='modern__brand section__padding'>
      <div>
        <img src={google} alt="google"></img>
      </div>
      <div>
        <img src={dropbox} alt="dropbox"></img>
      </div>
      <div>
        <img src={shopify} alt="shopify"></img>
      </div>
    </div>
  )
}

export default Brand
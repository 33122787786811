import React from 'react'
import { Feature } from '../../components'
import './features.css'


//create a Features data array
const featuresData =[
  {
    title: 'Allow us to build your brand with custom made Frameworks',
    text: 'Sit amet consectetur adipisicing elit. Magnam non modi sit, laudantium blanditiis reiciendis temporibus perferendis quo nobis maxime facilis voluptatibus consectetur, cum nemo laboriosam voluptas!.'
  },
  {
    title: 'Portray Your Portfolio to the World',
    text: 'Csit amet consectetur adipisicing elit. Magnam non modi sit, laudantium blanditiis reiciendis temporibus perferendis quo nobis maxime facilis voluptatibus consectetur.'
  },
  {
    title: 'Necesitas una traduccion en Español? No pasa nada, podemos hacerlo aqui a Modern AI',
    text: 'Consectetur adipisicing elit. Magnam non modi sit. Reiciendis temporibus perferendis quo nobis maxime facilis.'
  },
  {
    title: 'Product Managemnet and control',
    text: 'Magnam non modi sit, laudantium blanditiis reiciendis temporibus perferendis quo nobis maxime facilis.'
  }
]

const Features = () => {
  return (
    <div className="modern__features section__padding" id="features">
      <div className="modern__features-heading">
        <h1 className="gradient__text">The Future is Now and You Just Need To Realize It. Step into Future Today & Make it Happen.</h1>
        <p>Request Early Access to Get Started</p>
      </div>
      <div className="modern__features-container">
        {featuresData.map((item,index) => (
          <Feature title= {item.title} text={item.text} key={item.title + index}/>
        ))}
      </div>
    </div>
  )
}

export default Features
import React from 'react'
import { Feature } from '../../components'
import './whatmodern.css'


const WhatModern = () => {
  return (
    <div className="modern__whatmodern section__margin" id="modernai">
      <div className="modern__whatmodern-feature">
      <Feature title = "What is Modern AI?" text="Modern AI is Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus exercitationem repudiandae debitis vero, tempora quas nulla suscipit ut aut quod odit perspiciatis voluptates necessitatibus. Atque, ut. Rem perspiciatis laboriosam cupiditate, tempora vero architecto dolorum facilis optio animi, nulla accusantium iste doloribus eum impedit fuga!"/>
      </div>
      <div className="modern__whatmodern-heading">
        <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
        <p id="blogg">Explore Garrett's Modern AI </p>
      </div>
      <div className="modern__whatmodern-container">
        <Feature title="Back-end" text="Routing, High-tech authorization required, API's, Impeccable setup."/>
        <Feature title="Cyber Security" text="Implement first-class proccesses to help protect the confidentiality, integrity, and availability of computer systems, networks and data, against cyber-attacks or unauthorized access."/>
        <Feature title="The Future is Bright" text="Our vision coincides with the incredible expansion of the tech industry, although the expansion is rapid, Modern AI is ready to stay in front of the Curve."/>
      </div>
    </div>
  )
}

export default WhatModern
import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div className="modern_footer section__padding">
      <div className ="modern__footer-heading">
        <h1 className="gradient__text">Step into the future with Modern AI</h1>
      </div>
      <div className ="modern__footer-btn">
        <p>Request Early Access</p>
      </div>
      <div className="modern__footer-links">
        <div className="modern__footer_logo h3">
          <h3>Modern AI</h3>
          <p className="modern__footer_address">Somewhere in the world st. 96761, All Rights Reserved</p>
        </div>
        <div className='modern__footer-links_div'>
          <h4>Links</h4>
          <p>Negotiate</p>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contact</p>
        </div>
        <div className='modern__footer-links_div'>
          <h4>Company</h4>
          <p>Terms & conditions</p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className='modern__footer-links_div'>
          <h4>Get in Touch</h4>
          <p>This is an address 24532 street</p>
          <p>Call me 808-085-132567</p>
          <p>info@payme.net</p>
        </div>
      </div>
      <div className="modern__footer-copyright">
        <p>© 2023 Garrett's Modern AI. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer
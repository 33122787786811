import React from 'react'
import './possibility.css'
import possibilityImage from '../../assets/possibility.png'
import ai from '../../assets/ai.png'

const Possibility = () => {
  return (
    <div className="modern__possibility section__padding" id="possibility">
      <div className="modern__possibility-image">
        <img src={ai} alt="possibility" />
      </div>
      <div className="modern__possibility-content">
        <h4>Request early access to get started</h4>
        <h1 className="gradient__text">The possibilities with Modern AI are beyond your imagination</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam non modi sit, laudantium blanditiis reiciendis temporibus perferendis quo nobis maxime facilis voluptatibus consectetur, cum nemo laboriosam voluptas! Soluta, quaerat at. Doloremque, cupiditate.</p>
        <h4>The Possibilites are limitless</h4>
      </div>
    </div>
  )
}

export default Possibility